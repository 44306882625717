/** @jsx jsx */
import { jsx, useTheme, css } from "@emotion/react";
import modularityAreaContext from "@municipio/gatsby-theme-basic/src/modularityAreaContext.js";
import modularityModuleContext from "@municipio/gatsby-theme-basic/src/modularityModuleContext";
import modularityRowContext from "@municipio/gatsby-theme-basic/src/modularityRowContext";
import ModuleController from "@municipio/gatsby-theme-basic/src/wsui/components/ModuleController.jsx";
import {
  MaybeFragment,
  PageSection as DefaultPageSection,
  PageGrid,
  PageGridItem,
  useThemeProps,
  handleComponentsProp,
  Section,
} from "@wsui/base";
// import clsx from "clsx";
import { omit } from "lodash/fp";

import swirlsBackgroundAubergine from "../../../../../src/images/swirls-aubergine-background.svg";
import swirlsBackgroundBjork from "../../../../../src/images/swirls-bjork-background.svg";
import swirlsBackgroundEk from "../../../../../src/images/swirls-ek-background.svg";
import swirlsBackgroundNougat from "../../../../../src/images/swirls-nougat-background.svg";
import swirlsBackgroundPlommon from "../../../../../src/images/swirls-plommon-background.svg";

function defaultShouldMakePageSection({ moduleRow }) {
  if (moduleRow.modules.length > 1) {
    return true;
  }
  let module = moduleRow.modules[0];
  if (module.colspan !== 12) {
    return true;
  }
}

const backgroundColorMap = {
  "#401834": swirlsBackgroundAubergine,
  "#f4efd7": swirlsBackgroundBjork,
  "#d3a973": swirlsBackgroundEk,
  "#a1715f": swirlsBackgroundNougat,
  "#873b52": swirlsBackgroundPlommon,
};

export default function ModularityArea(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "ModularityArea" });
  let {
    moduleRows,
    defaultColspan = 7,
    context = {},
    gap = [8.75, 17.5],
    headingVariant,
    maxColspan,
    pageGridProps = {},
    sectionPadding = gap,
    components,
    promoteFirstHeading = false,
    shouldMakePageSection = defaultShouldMakePageSection,
    ...restProps
  } = omit(["marginAfter", "marginBefore"], props);

  let { PageSection } = handleComponentsProp(components, {
    PageSection: DefaultPageSection,
  });

  if (!moduleRows?.length) {
    return null;
  }

  // let allRowsHaveSameBackground = moduleRows.every((row, _, rows) => row.background === rows[0].background)
  return (
    <MaybeFragment {...restProps}>
      <modularityAreaContext.Provider value={{ ...context }}>
        {moduleRows.map((moduleRow, rowIndex) => {
          const hasQuoteModule = moduleRow.modules.find(
            (modules) => modules.module?.modBillboard?.format === "quote",
          );
          let { modules, background } = moduleRow;
          let backgroundColor =
            (background && theme.getColor(background)) || null;
          // let isFirstSection = rowIndex === 0;
          // let isLastSection = rowIndex === moduleRows.length - 1;
          return shouldMakePageSection(
            { moduleRow },
            defaultShouldMakePageSection,
          ) ?? true ? (
            <modularityRowContext.Provider
              key={rowIndex}
              value={{ modules, index: rowIndex }}
            >
              <PageSection
                css={css`
                  ${hasQuoteModule &&
                  `
                  background-repeat: no-repeat;
                  background-position: 100% 40%;
                  background-size: cover;
                  background-image: url(${backgroundColorMap[backgroundColor]})`}
                `}
                background={background}
                spacing={sectionPadding}
                // className={clsx({
                //   "wsui-modularity-area-page-section": true,
                // })}
              >
                <PageGrid
                  key={rowIndex}
                  as="div"
                  rowGap={gap}
                  maxColspan={maxColspan}
                  {...pageGridProps}
                >
                  {modules.map(
                    ({ hidden, module, colspan, ...rest }, index) => {
                      return (
                        <PageGridItem
                          key={index}
                          colspan={colspan || defaultColspan}
                        >
                          <Section
                            adjustLevel={
                              promoteFirstHeading &&
                              rowIndex === 0 &&
                              index === 0
                                ? -1
                                : 0
                            }
                          >
                            <modularityModuleContext.Provider
                              value={{
                                hidden,
                                module,
                                colspan,
                                headingVariant,
                                ...rest,
                              }}
                            >
                              <ModuleController module={module} />
                            </modularityModuleContext.Provider>
                          </Section>
                        </PageGridItem>
                      );
                    },
                  )}
                </PageGrid>
              </PageSection>
            </modularityRowContext.Provider>
          ) : (
            <div>
              {modules.map(({ hidden, module, colspan, ...rest }, index) => {
                return (
                  <Section
                    key={index}
                    adjustLevel={
                      promoteFirstHeading && rowIndex === 0 && index === 0
                        ? -1
                        : 0
                    }
                  >
                    <modularityModuleContext.Provider
                      value={{
                        hidden,
                        module,
                        colspan,
                        headingVariant,
                        ...rest,
                      }}
                    >
                      <ModuleController module={module} />
                    </modularityModuleContext.Provider>
                  </Section>
                );
              })}
            </div>
          );
        })}
      </modularityAreaContext.Provider>
    </MaybeFragment>
  );
}
